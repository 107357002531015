import ProductItem from "@/components/global/OrderDetail/ProductItem.vue";
import { getPurchaseOrderDetail } from "./api";
import {cancleOrderAll,cancleOrderLine} from '../api'
import Process from '../components/Process.vue'
import bg from '@/assets/purchaseOrder/bg.png'
import moment from "moment";
// import {cancelOrder} from '../components/util'
moment.locale("zh-cn");
import Btn from "@/components/global/Btn/Btn";
import {addCart, cancleChannelOrder} from "@/views/order/api";
import { findUserIdByShopAndPhone, queryUserByPhoneAndShopCode, selectServiceFree } from "@/views/order/details/api";

export default {
  name: "purchaseOrderDetail",
  components: { ProductItem,Process,Btn },
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/order/dms",
          name: "order",
          title: "采购订单列表"
        },
        {
          path: "/channelOrder/detail",
          name: "detail",
          title: "订单详情"
        }
      ],
      orderDetail:{
        orderHeader:{},
      },
      pageLoadFlag:false,
      bg:bg,
      visible: false,
      serviceFeeTotal:0,
      visibleInfo: false,
      cancelId: '', // 取消商品id
      dataArr:[],// 组合购数据
      bgimg: require("@/assets/noticeList/编组 13@2x.png"),
      intendedUserId:'',// 查看意向用户id
      brightNum:0,// 璀璨数量
      brightPrice:0,//璀璨价格
      olnyNum:0,// 专供数量
      olnyPrice:0,//专供价格
    };
  },
  computed:{
    totalProd:function (){
      let total = 0
      if(this.orderDetail&&Array.isArray(this.orderDetail.purchaseOrderItem)){
        this.orderDetail.purchaseOrderItem.forEach(item=>{
          total+=item.orderedQty
        })
      }
      return parseInt(total||0)

    },
    totalPrice:function (){
      let total = 0
      if(this.orderDetail&&Array.isArray(this.orderDetail.purchaseOrderItem)){
        this.orderDetail.purchaseOrderItem.forEach(item=>{
          total+=item.orderedQty*(item.price*100)/100
        })
      }
      return total
    },
    totalLoadVolume:function (){
      let total = 0
      if(this.orderDetail&&Array.isArray(this.orderDetail.purchaseOrderItem)){
        this.orderDetail.purchaseOrderItem.forEach(item=>{
          total+=item.orderedQty*Math.max(item.volume,0)
        })
      }
      return total
    }

  },
  mounted(){
    if(this.$route.query.id){
      this.getDetail(this.$route.query.id)
    }else{
      this.getDetail('')
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },
  methods:{
    toDetail(proCode, orgId) {
      // this.$router.push({
      //     path: '/product/detail',
      //     query: {
      //         productCode: proCode,
      //         orgId: orgId
      //     }
      // })
      let routeUrl = this.$router.resolve({
        path: '/product/detail',
        query: {
          productCode: proCode,
          orgId: orgId
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    // 去支付
    handleToPay(item){
      if(item.feeList) {
        let feeCode = item.feeList[0]
        this.$router.push({
          path: "/serviceFeeModule/payDetail",
          query: {
            id:feeCode,
            batch:0,
            b2bOrderCode: item.documentNum,
            orgName: '订单号',
            channel: 1
          }
        });
      } else {
        this.$message.warning('暂无待支付订单')
      }
    },
    // 退货单号 点击跳转退货单
    gotoDetail(item,type){
      this.$router.push({
        path:type=='zp'?'/purchaseReturn/purchaseReturnDetails':'/hisenseReturn/hisenseReturnDetailNew',
         query:{
           id:item.orderId
         }
      })
    },
    //点击关联订单号跳转页面
    gotoReatilDetail(itemId){
      this.$router.push({
        path: '/retailOrder/detailRetail',
        query: { id: itemId}
      });
      return
    },
    //查看用户档案gotoUsergotoUser
    gotoUser(){
      let data = {
        shopCode: this.orderDetail.storeCode, // 门店id
        phone: this.orderDetail.contactInfo // 手机号
      }
      queryUserByPhoneAndShopCode(data).then(res=>{
        if (res.data.code == 0 ) {
          this.intendedUserId = res.data.data != 0 ? res.data.data : ''
        }
      })
    },
    gotoUserDeatil() {
      this.$router.push({
        path: "/IntentionDetail",
        query: {
          id: this.intendedUserId
        }
      });
    },
    // 查看服务费
    gotoFee(item){
      this.$router.push({
        path: "/serviceFeeModule/serviceDetail",
        query: {
          id:item
        }
      });
    },
    // 打开取消订单弹窗
    openCancleChannelOrderAll(){
      this.visibleInfo = true
    },
    // 取消订单
    cancleChannelOrderAll(){
      let param = {
        userAccount : this.account,
        purchaseOrderId: this.orderDetail.id
      }
      this.$emit('postSpinTrue',true)
      cancleChannelOrder(param).then(res=>{
        this.$emit('postSpinTrue',false)
        let data = JSON.parse(res.data)
        if(data.code==0){
          this.$message.success('订单取消成功')
          this.getDetail(this.$route.query.id)
        }else{
          this.$message.success(data.msg)
        }

      }).catch(err=>{
        console.log('err',err);
      })
    },
    goBack() {
      // this.$router.go(-1);
      window.close()
    },
    // 加入购物车
    addCart(proid,orgid){
      let data = {productId:proid,orgId:orgid,num:1}
      addCart(data).then(res=>{
        if (res.data.code == 0) {
          this.$message.success("加入购物车成功");
        } else {
          this.$message.error(res.data.msg);
        }
      }).catch(error=>{
        this.$message.error('加入购物车失败');
        console.log('加入购物车',error);
      })
    },
    //组合购数据更改
    changeData(items) {
      var map = {},
        dest = [];
      for (var i = 0; i < items.length; i++) {
        var ai = items[i];
        if (!map[ai.productGroup]) {
          dest.push({
            productGroup: ai.productGroup,
            name: ai.productGroupRemark,
            num: ai.packageRateNum,
            data: [ai]
          });
          map[ai.productGroup] = ai;
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j];
            if (dj.productGroup == ai.productGroup) {
              dj.data.push(ai);
              break;
            }
          }
        }
      }

      return dest;
    },
    async getDetail(id){
      this.pageLoadFlag = true
      getPurchaseOrderDetail(id).then(res=>{
        if(typeof res.data == 'string'){
          this.orderDetail = JSON.parse(res.data).data;
        }else{
          this.orderDetail = res.data.data
        }
        // 获取专供
        const zg = this.orderDetail.purchaseOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('14182273113') > -1)
        if(zg.length>0) {
          zg.forEach(el=>{
            this.olnyNum+=Number(el.orderedQty) 
            this.olnyPrice+=Number(el.orderedQty) * Number(el.price);
          })
        }
        
        // 获取璀璨
        const cc = this.orderDetail.purchaseOrderItem.filter(el => el.productLabel&&el.productLabel.indexOf('15691143849') > -1)
        if(cc.length>0) {
          cc.forEach(el=>{
            this.brightNum+=Number(el.orderedQty) 
            this.brightPrice+=Number(el.orderedQty) * Number(el.price);
          })
        }
        this.gotoUser()
        if( this.orderDetail.feeList&&this.orderDetail.feeList.length>0 &&(this.orderDetail.deliveryMode == '04' || this.orderDetail.deliveryMode == '08')){
          let itemIds = this.orderDetail.purchaseOrderItem.map(it=>it.itemId)
          let data  = {
            orderType:2,
            orderItemIds: itemIds
          }
          selectServiceFree(data).then((resA)=>{
            if(resA.data.code == 0) {
              this.serviceFeeTotal = resA.data.data.total
              resA.data.data.items.forEach(item=>{
                this.orderDetail.purchaseOrderItem.forEach(itemTwo=>{
                  if(item.orderItemId == itemTwo.itemId) {
                    this.$nextTick(()=> {
                      this.$set(itemTwo,'serviceFee',item.serviceFee)
                    })
                  }
                })
              })
            } else {
              this.serviceFeeTotal = 0
            }

          })
        }

        if ( this.orderDetail.discountType == '90605'){
          this.dataArr.push(this.changeData(this.orderDetail.purchaseOrderItem))
          this.$forceUpdate()
        }
      }).finally(()=>{
        this.pageLoadFlag = false
      })


    },
    cancel(type,item){
      this.ordertypeCancel = type
      this.itemId = item&&item.id
      this.cancelOrder(this.orderDetail.orderHeader)
    },
    cancelOrder(item) {
      const orderType = item.orderTypeId;
      const states = item.orderStatusCode;
      if (
        orderType == "8311" &&
        (states == "ALREADYPLANPRODUCT" ||
          states == "ARRANGEDPRODUCT" ||
          states == "UNCHKED" ||
          states == "WAITDELIVER" ||
          states == "PARTCHECKED")
      ) {
        this.$confirm({
          title: '取消提醒',
          content:
            "取消‘评审通过’,‘已安排生产’,‘待排发货计划’,‘待发货’,‘发货中’状态的常规订单，会判定为商家违约，请确认是否取消？",
          onOk: () => {
            this.visible = true
          },
          onCancel() {

          }
        });
      }else{
        this.visible = true
      }
    },
    getReason(value){
        this.cancelReason = value
        const id = this.ordertypeCancel==1?this.$route.query.id:this.itemId
        this.cancleSubmit(id,this.ordertypeCancel )
    },
    cancleSubmit(id,type){
      this.pageLoadFlag = true
      let obj = {id:id,cancelReason:this.cancelReason}
      if(type==2){
      cancleOrderLine(obj).then(res=>{
          if(res.data.type=='Y'){
          this.$message.success('订单取消成功')
          this.getDetail(this.$route.query.id)
          }
      }).catch(error=>{
          console.log(error);
          this.$message.error('订单取消失败')
      })
      }else{
      cancleOrderAll(obj).then(res=>{
          if(res.data.type=='Y'){
          this.$message.success('订单取消成功')
          this.getDetail(this.$route.query.id)
          }
      }).catch(error=>{
          console.log(error);
          this.$message.error('订单取消失败')
      })
      }
    },
    handleOk(e) {
      this.visible = false;
    },
    getPrice(item){
      return ((item.price||0)*100)*(item.orderedQty||0)/100
    },
    formatDate(date){
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
    gotoProcess(){
      let routeUrl = this.$router.resolve({
        path:'/channelOrder/progress',
        query:{
          id:this.$route.query.id
        }
    });
    window.open(routeUrl.href, "_blank");
    }
  }
};
