/*
 * @Author: sqk
 * @Date: 2020-08-07 16:16:59
 * @LastEditTime: 2020-09-23 15:32:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\utils\api.js
 */
import service from "@/utils/request";
import dmshttp from "@/utils/dmsrequest";

//获取供应商、物料组
export function getBaseData() {
  return service({
    method: "get",
    url: "/order/orderList.htm",
  });
}
// 获取列表数据
export function getOrderList(params) {
  return service({
    method: "get",
    url: "/order/list.nd",
    params
  });
}
// 取消整笔订单
export function cancleOrderAll(params) {
  return service({
    method: "post",
    url: "/order/cancelHeader.nd",
    params
  });
}
// 取消商品行
export function cancleOrderLine(params) {
  return service({
    method: "post",
    url: "/order/cancelLine.nd",
    params
  });
}
// 加入购物车
export function addCart(params) {
  return service({
    method: "get",
    url: "/cart/addToCartNew.nd",
    params
  });
}
// 订单导出
export function exportOrder(params) {
  return service({
    method: "post",
    url: "/order/exportOrder.nd",
    params,
    responseType: 'blob'
  });
}
// 审核单导出
export function exportOrderSap(params) {
  return service({
    method: "post",
    url: "/order/exportSapOrder.nd",
    params,
    responseType: 'blob'
  });
}
//渠道采购订单列表接口
export function getChannelOrderList(data) {
  return dmshttp({
    url: "findDistributorPurchaseOrderList",
    data
  });
}
//渠道采购取消订单接口
export function cancleChannelOrder(data) {
  return dmshttp({
    url: "cancelPurchaseOrderByDistributor",
    data
  });
}
export function changeTransFlag(data) {
  return service({
      url: '/marketActivity/changeTransFlag.nd',
      method: 'post',
      data
  })
}
